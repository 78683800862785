import { HOST_PORT, HOST_PROTOCOL } from '../constants';

export const emailPatt = /^.+@.+\..+$/;

export const handleError = (err: any) => {
  console.error(err);
};

export const addressPatt = /^[0123456789abcdef]{40}$/i;

export const validAddress = (address: string): boolean => {
  return addressPatt.test(address);
};

export const shortenAddress = (address: string): string => {
  return `${address.slice(0, 8)}...${address.slice(-8)}`;
};

export const generateEndpointUrl = (endpoint: string): string => {
  return `${HOST_PROTOCOL}://${endpoint}:${HOST_PORT}`;
};
