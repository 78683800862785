import React, { useContext } from 'react';
import { Card, CardBody, CardHeader } from './card';
import { FlexRow } from './flex';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import swal from 'sweetalert';
import { CCApiContext } from '../../hooks/cc-api-hook';
import { POKT_MIN_PER_NODE, POKT_RECOMMENDED_PER_NODE } from '../../constants';
import { handleError } from '../../util';

export const PoktAccountCard = () => {

  const {
    account,
    balance,
    nodes,
    sessionToken
  } = useSelector(({ appState }: RootState) => appState);
  const ccAPI = useContext(CCApiContext);

  const styles = {
    balance: {
      overflowX: 'hidden',
      minWidth: 0,
    },
    copyIcon: {
      marginTop: -6,
      // marginBottom: -12,
      fontSize: 20,
    },
  };

  const balanceNum = Number(balance) || 0;

  const handleViewPrivateKeyClick = async (e: React.MouseEvent) => {
    e.preventDefault();
    const password = await swal({
      title: 'Enter user password',
      content: {
        element: "input",
        attributes: {
          placeholder: "Enter your password",
          type: "password",
        },
      },
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'View Private Key',
          visible: true,
          closeModal: false,
        },
      },
    });
    if(!password) {
      if(swal.close)
        swal.close();
      return;
    }
    if(!sessionToken)
      return;
    let privateKey: string;
    try {
      privateKey = await ccAPI.accountPrivateKey(sessionToken, password);
      if(!privateKey) {
        if(swal.close)
          swal.close();
        return;
      }
    } catch(err: any) {
      console.error(err);
      await swal({
        icon: 'error',
        title: 'Error',
        text: err.message,
      });
      return;
    }
    await swal({
      title: 'Shared Wallet Private Key',
      text: privateKey,
    });
  };

  const handleCopyAddressClick = async (e: React.MouseEvent, address: string) => {
    e.preventDefault();
    navigator.clipboard.writeText(address)
      .catch(handleError);
  };

  if(!account)
    return <div></div>;

  const nodeNum = nodes.length;

  const minPokt = nodeNum * POKT_MIN_PER_NODE;
  const balanceDiff = balanceNum - minPokt;

  const enabled = !account.disabled;
  const updating = !enabled && balanceDiff >= 0 && nodeNum > 0;
  const disabled = !enabled && !updating;

  return (
    <Card>
      <CardHeader>
        <h4>
          <FlexRow justifyContent={'space-between'} wrap={'nowrap'} alignItems={'center'}>
            <span>POKT Shared Wallet</span>
            <a href={'#'} title={'View account private key'} onClick={handleViewPrivateKeyClick}><i className={'mdi mdi-eye'} /></a>
          </FlexRow>
        </h4>
      </CardHeader>
      <CardBody>
        <h4 className={'mt-0'}>Address:</h4>
        <FlexRow justifyContent={'space-between'} wrap={'nowrap'}>
          <div className={'text-monospace'}><small><a href={`https://poktscan.com/account/${account.poktAddress}`} title={'View account on poktscan'}>{account.poktAddress}</a></small></div>
          <a title={'Copy endpoint'} style={styles.copyIcon} href={'#'} onClick={(e) => handleCopyAddressClick(e, account.poktAddress)}><i className={'mdi mdi-content-copy'} /></a>
        </FlexRow>
        <h4>Balance:</h4>
        <FlexRow justifyContent={'space-between'} wrap={'nowrap'}>
          <div className={`text-monospace font-weight-bold ${(disabled || balanceDiff < 0) ? 'text-danger' : 'text-success'}`} style={styles.balance as React.CSSProperties}>{balance ? balance.slice(0, 30) : ''}</div>
          <div><small>POKT</small></div>
        </FlexRow>
        <h4>Endpoint Status:</h4>
        <FlexRow justifyContent={'space-between'} wrap={'nowrap'}>
          <div className={`text-monospace font-weight-bold ${disabled ? 'text-danger' : updating ? 'text-warning' : 'text-success'}`} style={styles.balance as React.CSSProperties}>
            {balance.length === 0 ? '' : enabled ? 'ENABLED' : updating ? 'UPDATING' : 'DISABLED'}
          </div>
        </FlexRow>
        <FlexRow>
          <small className={`${disabled ? 'text-danger' : updating ? 'text-warning' : ''}`}>{
            balance.length === 0 ?
              ''
              :
              enabled ?
                ''
                :
                updating ?
                  'You currently have enough POKT to enable RPC endpoints. The system will update in a few minutes and enable your endpoints.'
                  :
                  balanceNum === 0 && nodeNum === 0 ?
                    <span>RPC endpoints are disabled while the account balance is less than <strong>{minPokt} POKT</strong> per node. The recommendation is <strong>{POKT_RECOMMENDED_PER_NODE} POKT</strong> per node.</span>
                    :
                    <span>RPC endpoints are disabled while the account balance is less than <strong>{minPokt} POKT</strong> per node. The recommendation is <strong>{POKT_RECOMMENDED_PER_NODE} POKT</strong> per node. Add <strong>{(POKT_RECOMMENDED_PER_NODE * nodeNum) - balanceNum} POKT</strong> to meet the recommended amount for {nodeNum} {nodeNum === 1 ? 'node' : 'nodes'}.</span>
          }</small>
        </FlexRow>
      </CardBody>
    </Card>
  );
};
