export const routes = {
  HOME: '/',
  REGISTER: '/register',
  INVITE: '/invite',
  USER_SETTINGS: '/user-settings',
  RELAY_INVOICES: '/relay-invoices',
  DOCS: '/docs',
  SUPPORT: '/support',
  ADD_NODES: '/add-nodes',
};

export const RECAPTCHA_SITEKEY = process.env.REACT_APP_RECAPTCHA_SITEKEY || '';

export const localStorageKeys = {
  SESSION_TOKEN: 'SESSION_TOKEN',
};

export const apiPath = process.env.REACT_APP_CC_API_PATH || '';

export const staticLinks = {
  PRIVACY_POLICY: 'https://files.decentralizedauthority.com/privacy_policy.html',
  TERMS_OF_SERVICE: 'https://files.decentralizedauthority.com/tos.html',
  COOKIE_POLICY: 'https://www.iubenda.com/privacy-policy/20564943/cookie-policy?ifr=true&height=755&newmarkup=yes&an=no',
};

export const SUPPORT_EMAIL = 'support@cc.nodepilot.tech';

export const HOST_PROTOCOL = 'http';
export const HOST_PORT = 39880;

export const POKT_MIN_PER_NODE = 50;
export const POKT_RECOMMENDED_PER_NODE = 100;
