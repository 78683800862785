import React, { useContext, useEffect, useRef, useState } from 'react';
import { Login } from './login';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { BodyContainer } from './ui/body-container';
import { Card, CardBody, CardHeader } from './ui/card';
import { Form, FormGroup } from './ui/form';
import { FlexColumn, FlexRow } from './ui/flex';
import { RECAPTCHA_SITEKEY, SUPPORT_EMAIL } from '../constants';
import ReCaptcha from 'react-google-recaptcha';
import { emailPatt, handleError } from '../util';
import { setAccount } from '../reducers/app-reducer';
import { CCApiContext } from '../hooks/cc-api-hook';
import swal from 'sweetalert';

export const UserSettings = () => {

  const ccAPI = useContext(CCApiContext);

  const dispatch = useDispatch();
  const {
    account,
    sessionToken,
  } = useSelector(({ appState }: RootState) => appState);

  const [ email, setEmail ] = useState(account?.email || '');
  const [ domains, setDomains ] = useState(account?.domains || []);
  const [ currentPassword, setCurrentPassword ] = useState('');
  const [ profileErrorMessage, setProfileErrorMessage ] = useState('');
  const [ profileWarningMessage, setProfileWarningMessage ] = useState('');
  const [ profileSuccessMessage, setProfileSuccessMessage ] = useState('');
  const [ disableProfile, setDisableProfile ] = useState(false);
  const [ password, setPassword ] = useState('');
  const [ passwordRepeat, setPasswordRepeat ] = useState('');
  const [ passwordErrorMessage, setPasswordErrorMessage ] = useState('');
  const [ passwordWarningMessage, setPasswordWarningMessage ] = useState('');
  const [ passwordSuccessMessage, setPasswordSuccessMessage ] = useState('');
  const [ disablePassword, setDisablePassword ] = useState(false);

  useEffect(() => {
    if(account) {
      setEmail(account.email);
      setDomains(account.domains);
    } else {
      setEmail('');
    }
  }, [account]);

  const styles = {
    container: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      overflowX: 'hidden',
    },
    warningMessage: {
      marginBottom: -4,
    },
  };


  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setEmail(e.target.value.trim().toLowerCase());
  };

  const captchaRef = useRef(null);

  const onProfileUpdateSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if(!sessionToken || !account)
      return;
    setProfileErrorMessage('');
    setProfileWarningMessage('');
    setProfileSuccessMessage('');
    // @ts-ignore
    const token = captchaRef ? captchaRef.current.getValue() : '';
    if(!token) {
      setProfileErrorMessage('reCAPTCHA not found. Please reload page.');
      return;
    } else if(!emailPatt.test(email)) {
      setProfileWarningMessage('Please enter a valid email address.');
      return;
    }
    setDisableProfile(true);
    try {
      const success = await ccAPI.accountUpdateEmail(sessionToken, email, token);
      if(success) {
        setProfileSuccessMessage('Email successfully updated.');
        if(account)
          dispatch(setAccount({account: {...account, email}}));
      } else {
        setProfileErrorMessage('Unable to update email.');
      }
      setDisableProfile(false);
    } catch(err: any) {
      handleError(err);
      setProfileErrorMessage(err.message);
      setDisableProfile(false);
    }
  };

  const onCurrentPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setCurrentPassword(e.target.value);
  };
  const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setPassword(e.target.value);
  };
  const onPasswordRepeatChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setPasswordRepeat(e.target.value);
  };
  const onPasswordUpdateSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if(!sessionToken || !account)
      return;
    setPasswordWarningMessage('');
    setPasswordErrorMessage('');
    setPasswordSuccessMessage('');
    setDisablePassword(true);
    try {
      if(password.length < 12) {
        setPasswordWarningMessage('Password must be at least twelve characters long.');
        setDisablePassword(false);
        return;
      } else if(password !== passwordRepeat) {
        setPasswordWarningMessage('Password and repeated password must match.');
        setDisablePassword(false);
        return;
      }
      const success = await ccAPI.accountUpdatePassword(sessionToken, currentPassword, password);
      if(success) {
        setPasswordSuccessMessage('Password successfully updated.');
      } else {
        setPasswordErrorMessage('Unable to update password.');
      }
      setCurrentPassword('');
      setPassword('');
      setPasswordRepeat('');
      setDisablePassword(false);
    } catch(err: any) {
      handleError(err);
      setPasswordErrorMessage(err.message);
      setDisablePassword(false);
    }
  };

  const onDeleteAccountClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if(!sessionToken)
      return;
    const confirmed = await swal({
      icon: 'warning',
      title: 'Delete Account',
      text: 'Are you sure that you want to permanently delete your account? This action cannot be undone.',
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Delete Account',
          visible: true,
          closeModal: false,
          className: 'bg-danger',
        },
      },
    });
    if(!confirmed)
      return;
    const password = await swal({
      title: 'Enter password',
      content: {
        element: "input",
        attributes: {
          placeholder: "Enter your password",
          type: "password",
        },
      },
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Delete Account',
          visible: true,
          closeModal: false,
          className: 'bg-danger',
        },
      },
    });
    if(!password)
      return;
    try {
      const success = await ccAPI.accountDelete(sessionToken, password);
      if(success) {
        localStorage.clear();
        // @ts-ignore
        window.location = '/';
      }
    } catch(err: any) {
      handleError(err);
      await swal({
        icon: 'error',
        title: 'Oops!',
        text: err.message,
      });
    }
  };

  if(!sessionToken && !account)
    return <Login />;
  else if(!account)
    return <div />;

  return (
    <BodyContainer>
      <div style={styles.container as React.CSSProperties}>
        <div className={'container'}>

          <div className={'row'}>
            <div className={'col'}>
              <FlexRow justifyContent={'space-between'}>
                <h2>User Settings</h2>
                <FlexColumn justifyContent={'center'} alignItems={'center'}>
                  <button type={'submit'} className={'btn btn-sm btn-danger'} onClick={onDeleteAccountClick}>Delete Account</button>
                </FlexColumn>
              </FlexRow>
            </div>
          </div>

          <div className={'row'}>

            <div className={'col-lg-6'}>

              <Card className={'mb-2'}>
                <CardHeader>
                  <h4>User Profile</h4>
                </CardHeader>
                <CardBody>

                  <FormGroup>
                    <label>Email Address:</label>
                    <input type={'email'} className={'form-control'} value={email} readOnly />
                  </FormGroup>

                  <FormGroup>
                    <label>Domains:</label>
                    <input type={'text'} className={'form-control'} value={domains.join(', ')} readOnly />
                  </FormGroup>

                  <div className={'text-muted'}>
                    Need to add another domain? Contact <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>.
                  </div>

                </CardBody>
              </Card>

              {/*<Card className={'mb-2'}>*/}
              {/*  <CardHeader>*/}
              {/*    <h4>Update User Profile</h4>*/}
              {/*  </CardHeader>*/}
              {/*  <CardBody>*/}
              {/*    <Form onSubmit={onProfileUpdateSubmit}>*/}

              {/*      <FormGroup>*/}
              {/*        <label>Email Address:</label>*/}
              {/*        <input type={'email'} className={'form-control'} placeholder={'Enter email address'} value={email} onChange={onEmailChange} readOnly={disableProfile} required />*/}
              {/*      </FormGroup>*/}

              {/*      <ReCaptcha sitekey={RECAPTCHA_SITEKEY}*/}
              {/*                 theme={'dark'} ref={captchaRef}/>*/}

              {/*      {profileWarningMessage ?*/}
              {/*        <div className={'text-warning mt-2'} style={styles.warningMessage}>{profileWarningMessage}</div>*/}
              {/*        :*/}
              {/*        null*/}
              {/*      }*/}

              {/*      {profileErrorMessage ?*/}
              {/*        <div className={'text-danger mt-2'} style={styles.warningMessage}>{profileErrorMessage}</div>*/}
              {/*        :*/}
              {/*        null*/}
              {/*      }*/}

              {/*      {profileSuccessMessage ?*/}
              {/*        <div className={'text-success mt-2'} style={styles.warningMessage}>{profileSuccessMessage}</div>*/}
              {/*        :*/}
              {/*        null*/}
              {/*      }*/}

              {/*      <button type={'submit'} className={'btn btn-primary mt-3'} disabled={disableProfile || !email || email === account?.email}>Save Changes</button>*/}

              {/*    </Form>*/}
              {/*  </CardBody>*/}
              {/*</Card>*/}

              <Card className={'mb-2'}>
                <CardHeader>
                  <h4>Update Password</h4>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={onPasswordUpdateSubmit}>

                    <FormGroup>
                      <label>Current Password:</label>
                      <input type={'password'} className={'form-control'} placeholder={'Enter current password'} value={currentPassword} onChange={onCurrentPasswordChange} readOnly={disablePassword} required />
                    </FormGroup>

                    <FormGroup>
                      <label>Password:</label>
                      <input type={'password'} className={'form-control'} placeholder={'Enter new password'} value={password} onChange={onPasswordChange} readOnly={disablePassword} required />
                    </FormGroup>

                    <FormGroup>
                      <label>Repeat Password:</label>
                      <input type={'password'} className={'form-control'} placeholder={'Repeat new password'} value={passwordRepeat} onChange={onPasswordRepeatChange} readOnly={disablePassword} required />
                    </FormGroup>

                    {passwordWarningMessage ?
                      <div className={'text-warning'} style={styles.warningMessage}>{passwordWarningMessage}</div>
                      :
                      null
                    }

                    {passwordErrorMessage ?
                      <div className={'text-danger'} style={styles.warningMessage}>{passwordErrorMessage}</div>
                      :
                      null
                    }

                    {passwordSuccessMessage ?
                      <div className={'text-success'} style={styles.warningMessage}>{passwordSuccessMessage}</div>
                      :
                      null
                    }

                    <button type={'submit'} className={'btn btn-primary mt-3'} disabled={!currentPassword || !password || !passwordRepeat || disablePassword}>Save Changes</button>

                  </Form>
                </CardBody>
              </Card>

            </div>

            <div className={'col-lg-6'}>

              <Card className={'mb-2'}>
                <CardHeader>
                  <FlexRow justifyContent={'space-between'}>
                    <h4>Terms of Service</h4>
                    <h4>
                      <a href={'https://files.decentralizedauthority.com/tos.html'} target={'_blank'}><i className={'mdi mdi-open-in-new'} /></a>
                    </h4>
                  </FlexRow>
                </CardHeader>
                <CardBody>
                  Accepted at <strong>{new Date(account.agreeTosDate).toLocaleTimeString()}</strong> on <strong>{new Date(account.agreeTosDate).toLocaleDateString()}</strong>
                </CardBody>
              </Card>

              <Card className={'mb-2'}>
                <CardHeader>
                  <FlexRow justifyContent={'space-between'}>
                    <h4>Privacy Policy</h4>
                    <h4>
                      <a href={'https://files.decentralizedauthority.com/privacy_policy.html'} target={'_blank'}><i className={'mdi mdi-open-in-new'} /></a>
                    </h4>
                  </FlexRow>
                </CardHeader>
                <CardBody>
                  Accepted at <strong>{new Date(account.agreePrivacyPolicyDate).toLocaleTimeString()}</strong> on <strong>{new Date(account.agreeTosDate).toLocaleDateString()}</strong>
                </CardBody>
              </Card>

            </div>

          </div>

        </div>
      </div>
    </BodyContainer>
  );
};
