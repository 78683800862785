import React, { useContext, useEffect, useState } from 'react';
import { BodyContainer } from './ui/body-container';
import { CCApiContext } from '../hooks/cc-api-hook';
import { DocsObj } from '../interfaces';
import { Card, CardBody, CardHeader } from './ui/card';
import { apiPath } from '../constants';
import { handleError } from '../util';

const descriptions: {[route: string]: {[method: string]: string}} = {
  '/v1/version': {
    'GET': 'Gets the Community Chains API server version.',
  },
  '/v1/unlock': {
    'POST': 'Authenticates user and returns a session token (valid for 24 hours) to use when making authenticated requests.'
  },
  '/v1/accounts/{id}': {
    'GET': 'Gets a user\'s account.',
  },
  '/v1/accounts/{id}/update-password': {
    'POST': 'Updates a user\'s password.'
  },
  '/v1/accounts/{id}/private-key': {
    'POST': 'Get a user\'s shared POKT account private key.',
  },
  '/v1/accounts/{id}/balance': {
    'GET': 'Gets the balance (in POKT) of a user\'s shared POKT account.',
  },
  '/v1/accounts/{id}/add-chain': {
    'POST': 'Adds a chain to a user\'s account.'
  },
  '/v1/accounts/{id}/remove-chain': {
    'POST': 'Removes a chain from a user\'s account.'
  },
  '/v1/accounts/{id}/update-chains': {
    'POST': 'Updates/replaces chains in a user\'s account.'
  },
  '/v1/accounts/{id}/delete': {
    'POST': 'Permanently deletes a user\'s account and all associated nodes.',
  },
  '/v1/chains': {
    'GET': 'Gets all available chains.',
  },
  '/v1/chains/{id}': {
    'GET': 'Gets a chain\'s data.',
  },
  '/v1/nodes': {
    'GET': 'Gets all of the user\'s registered nodes.',
    'POST': 'Registers a new node.',
  },
  '/v1/nodes/{address}': {
    'GET': 'Gets a registered node\'s data',
  },
  '/v1/nodes/{address}/delete': {
    'POST': 'Deletes a registered node.',
  },
  '/v1/docs': {
    'GET': 'Gets API docs in JSON format.',
  },
};

export const Docs = () => {

  const ccAPI = useContext(CCApiContext);

  const [ docs, setDocs ] = useState<DocsObj>({});
  const [ selectedRoute, setSelectedRoute ] = useState<string>('');

  useEffect(() => {
    ccAPI.docs()
      .then(res => {
        const routes = Object.keys(res);
        if(routes.length > 0) {
          setDocs(res);
          setSelectedRoute(routes[0])
        }
      })
      .catch(err => {
        handleError(err);
      });
  }, [ccAPI]);

  const styles = {
    container: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
    docsOuterContainer: {
      position: 'relative',
      flexGrow: 1,
      minHeight: 0,
    },
    docsInnerContainer: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      overflowX: 'hidden',
      overflowY: 'auto',
    },
  };

  const onRouteClick = (e: React.MouseEvent, route: string) => {
    e.preventDefault();
    setSelectedRoute(route);
  };

  return (
    <BodyContainer>
      <div className={'d-flex flex-column justify-content-start'} style={styles.container as React.CSSProperties}>
        <div className={'container-fluid'}>
          <h1>Community Chains API Documentation</h1>
        </div>
        <div className={'container-fluid'}>
          <p>Public API Endpoint: <span className={'ml-2 text-monospace font-weight-bold'}>{apiPath}</span></p>
        </div>
        <div style={styles.docsOuterContainer as React.CSSProperties}>
          <div style={styles.docsInnerContainer as React.CSSProperties}>
            <div className={'container-fluid'}>
              <div className={'row'}>

                <div className={'col'}>
                  {Object.keys(docs)
                    .map((route) => {
                      return (
                        <h4 key={route}><a className={route === selectedRoute ? 'text-underline' : ''} href={'#'} onClick={e => onRouteClick(e, route)}>{route}</a></h4>
                      );
                    })
                  }
                </div>

                {selectedRoute ?
                  <div className={'col'}>
                    <Card className={'mb-1'}>
                      <CardHeader>
                        <h3>{selectedRoute}</h3>
                      </CardHeader>
                      <CardBody>
                        {docs[selectedRoute]
                          .map((methodObj, idx) => {
                            const description = descriptions[selectedRoute] && descriptions[selectedRoute][methodObj.method] ?
                              descriptions[selectedRoute][methodObj.method]
                              :
                              'none';
                            return (
                              <div key={`${selectedRoute}-${methodObj.method}`} className={idx > 0 ? 'mt-3' : ''}>
                                <p>Method:</p>
                                <h4 className={'ml-2'}>{methodObj.method}</h4>
                                <p>Description:</p>
                                <p className={'ml-2'}>{description}</p>
                                <p className={'mt-2'}>Authentication Required:</p>
                                <p className={'ml-2'}>{methodObj.auth ? <span><span className={'text-monospace font-weight-bold'}>x-api-key</span> header (returned from <a href={'#'} onClick={e => onRouteClick(e, '/v1/unlock')}>/v1/unlock</a> request)</span> : 'none'}</p>
                                <p className={'mt-2'}>Request Body:</p>
                                <code className={'ml-2'}>
                                  {methodObj.reqBody ? JSON.stringify(methodObj.reqBody, null, '  ') : 'none'}
                                </code>
                              </div>
                            );
                          })
                        }
                      </CardBody>
                    </Card>
                  </div>
                  :
                  null
                }

              </div>
            </div>
          </div>
        </div>
      </div>
    </BodyContainer>
  );
};
