import React from 'react';

export interface FormProps {
  children?: any,
  className?: string,
  style?: React.CSSProperties,
  onSubmit?: (e: React.FormEvent) => void
}
export const Form = ({ children, className = '', style = {}, onSubmit }: FormProps) => {
  return (
    <form className={`${className}`} style={style} onSubmit={onSubmit}>
      {children}
    </form>
  );
};

export const FormGroup = ({ children, className = '', style = {} }: {children?: any, className?: string, style?: React.CSSProperties}) => {
  return (
    <div className={`form-group ${className}`} style={style}>
      {children}
    </div>
  );
};
