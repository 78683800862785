import React, { useContext, useState } from 'react';
import { Login } from '../login';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { BodyContainer } from './body-container';
import { FlexRow } from './flex';
import uniq from 'lodash/uniq';
import { handleError, validAddress } from '../../util';
import swal from 'sweetalert';
import { CCApiContext } from '../../hooks/cc-api-hook';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../constants';
import { setNodes } from '../../reducers/app-reducer';

export const UpdateNodesForm = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    account,
    sessionToken,
  } = useSelector(({ appState }: RootState) => appState);
  const ccAPI = useContext(CCApiContext);

  const [ addressText, setAddressText ] = useState('');
  const [ disableSubmit, setDisableSubmit ] = useState(false);

  const styles = {
    container: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      overflowY: 'auto',
    },
    checkAll: {
      fontSize: 20,
    },
    submitButton: {
      minWidth: 200,
    },
  };

  const onTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    setAddressText(e.target.value);
  };

  const onAddSubmit = async (e: React.MouseEvent) => {
    e.preventDefault();
    if(!sessionToken)
      return;
    const addresses = uniq(addressText
      .split('\n')
      .map(s => s.split(','))
      .reduce((arr, a) => {
        return [...arr, ...a];
      }, [])
      .map(s => s.trim().toLowerCase())
      .filter(s => !!s));
    setAddressText(addresses.join('\n'));
    const invalidAddresses = addresses
      .filter(a => !validAddress(a));
    if(invalidAddresses.length > 0) {
      await swal({
        icon: 'warning',
        title: 'Invalid Addresses',
        text: `The following addresses are not valid:\n\n${invalidAddresses.join('\n')}\n\nPlease fix or remove them and try again.`,
      });
      return;
    }
    setDisableSubmit(true);
    try {
      await Promise.all(addresses.map(a => ccAPI.nodesCreate(sessionToken, a)));
      // await Promise.all(addresses.map(a => ccAPI.nodeUpdateChains(sessionToken, a, selectedChains.toArray())));
      ccAPI.nodes(sessionToken)
        .then(nodes => {
          dispatch(setNodes({nodes}));
        })
        .catch(err => {
          handleError(err);
        });
      await swal({
        icon: 'success',
        title: 'Success!',
        text: 'All nodes were successfuly registered.',
      });
      navigate(routes.HOME);
    } catch(err: any) {
      setDisableSubmit(false);
      handleError(err);
      await swal({
        icon: 'error',
        title: 'Oops!',
        text: err.message,
      });
    }
  };

  if(!sessionToken && !account)
    return <Login />;
  else if(!account)
    return <div />;

  return (
    <BodyContainer>
      <div style={styles.container as React.CSSProperties}>
        <div className={'container'}>

          <FlexRow justifyContent={'space-between'} alignItems={'center'}>
            <h2>Add Node(s)</h2>
            <div>
              <button style={styles.submitButton} className={'btn btn-primary'} onClick={onAddSubmit} disabled={disableSubmit}>{'Submit'}</button>
            </div>
          </FlexRow>

          <div className={'form-group mt-2'}>
            <label>Node Addresses: <em className={'text-muted'}>(separate by comma and/or line break)</em></label>
            <textarea className={'form-control text-monospace'} value={addressText} readOnly={disableSubmit}
                      rows={5} placeholder={`Enter POKT addresses of staked nodes to add`} onChange={onTextareaChange} />
          </div>

        </div>
      </div>
    </BodyContainer>
  );
};
