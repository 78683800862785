import React from 'react';

export const Card = ({ children, className = '', style = {} }: {children?: any, className?: string, style?: React.CSSProperties}) => {
  return (
    <div className={`card ${className}`} style={style}>
      {children}
    </div>
  );
};

export const CardHeader = ({ children, className = '', style = {} }: {children?: any, className?: string, style?: React.CSSProperties}) => {
  return (
    <div className={`card-header ${className}`} style={style}>
      {children}
    </div>
  );
};

export const CardBody = ({ children, className = '', style = {} }: {children?: any, className?: string, style?: React.CSSProperties}) => {
  return (
    <div className={`card-body ${className}`} style={style}>
      {children}
    </div>
  );
};
