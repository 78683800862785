import React, { useContext, useState } from 'react';
import { FlexColumn, FlexRow } from './ui/flex';
import { Link } from 'react-router-dom';
import { localStorageKeys, routes } from '../constants';
import daLogo from '../images/da_logo_white-400.png';
import { useDispatch } from 'react-redux';
import { setSessionToken } from '../reducers/app-reducer';
import { CCApiContext } from '../hooks/cc-api-hook';
import { handleError } from '../util';
import ccLogoText from '../images/cc_logo_text.svg';

export const Login = () => {

  const dispatch = useDispatch();
  const ccAPI = useContext(CCApiContext);

  const [ email, setEmail ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ warningMessage, setWarningMessage ] = useState('');
  const [ errorMessage, setErrorMessage ] = useState('');
  const [ disabled, setDisabled ] = useState(false);

  const styles = {
    container: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
    formContainer: {
      marginTop: -100,
    },
    warningMessage: {
      marginBottom: -4,
    },
    logo: {
      width: 200,
    },
  };

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value.trim().toLowerCase());
  };
  const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };
  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setErrorMessage('');
    setWarningMessage('');
    setDisabled(true);
    try {
      const sessionToken = await ccAPI.unlock(email, password);
      localStorage.setItem(localStorageKeys.SESSION_TOKEN, JSON.stringify(sessionToken));
      dispatch(setSessionToken({sessionToken}));
    } catch(err: any) {
      handleError(err);
      setErrorMessage(err.message);
      setDisabled(false);
    }
  };

  return (
    <FlexColumn style={styles.container as React.CSSProperties} justifyContent={'center'}>

      <div className={'flex-grow-1'} />

      <div className={'row'}>
        <div className={'col'} />
        <div className={'col-sm-8 col-md-6 col-lg-4 col-xl-3'} style={styles.formContainer}>
          <div className={'pl-1 pr-1'}>

            <div className={'mb-4'}>
              <img className={'w-100'} alt={'Community Chains'} src={ccLogoText} />
            </div>

            <form onSubmit={onSubmit}>

              <div className={'form-group'}>
                <label>Email:</label>
                <label style={{display: 'none'}}>Username</label>
                <input type={'text'} className={'form-control'}
                       onChange={onEmailChange} value={email}
                       readOnly={disabled} name={'username'} id={'username'} required autoFocus />
              </div>

              <div className={'form-group'}>
                <label>Password:</label>
                <input type={'password'} className={'form-control'}
                       onChange={onPasswordChange} value={password}
                       readOnly={disabled} name={'password'} id={'password'} required />
              </div>

              {warningMessage ?
                <div className={'text-warning'} style={styles.warningMessage}>{warningMessage}</div>
                :
                null
              }

              {errorMessage ?
                <div className={'text-danger'} style={styles.warningMessage}>{errorMessage}</div>
                :
                null
              }

              <div className={'form-group'}>
                <button type={'submit'} className={'btn btn-primary btn-lg d-block w-100 mt-3'} disabled={disabled}>Log In</button>
              </div>

            </form>

            <div className={'text-center'}>
              Not registered yet? <Link to={routes.INVITE}>Request an invite.</Link>
            </div>

          </div>
        </div>
        <div className={'col'} />
      </div>

      <div className={'flex-grow-1'} />

      <FlexRow justifyContent={'center'}>
        <img style={styles.logo} className={'mb-1'} src={daLogo} alt={'Decentralized Authority Logo'} />
      </FlexRow>

    </FlexColumn>
  );
};
