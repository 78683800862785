import React, { useContext, useEffect } from 'react';
import { AppContainer } from './components/ui/app-container';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import { Home } from './components/home';
import { localStorageKeys, routes } from './constants';
import { Register } from './components/register';
import { Invite } from './components/invite';
import dayjs from 'dayjs';
import { SessionToken } from './interfaces';
import { setAccount, setBalance, setChains, setNodes, setPoktQueryData, setSessionToken } from './reducers/app-reducer';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './store';
import { UserSettings } from './components/user-settings';
import { Docs } from './components/docs';
import { CCApiContext } from './hooks/cc-api-hook';
import { handleError } from './util';
import { Support } from './components/support';
import { AddNodes } from './components/add-nodes';
import { RelayInvoices } from './components/relay-invoices';

const router = createBrowserRouter([
  {
    path: routes.HOME,
    element: <Home />,
  },
  {
    path: routes.INVITE,
    element: <Invite />,
  },
  {
    path: routes.REGISTER,
    element: <Register />,
  },
  {
    path: routes.ADD_NODES,
    element: <AddNodes />,
  },
  {
    path: routes.RELAY_INVOICES,
    element: <RelayInvoices />,
  },
  {
    path: routes.USER_SETTINGS,
    element: <UserSettings />,
  },
  {
    path: routes.SUPPORT,
    element: <Support />,
  },
  {
    path: routes.DOCS,
    element: <Docs />,
  },
]);

function App() {

  const ccAPI = useContext(CCApiContext);

  const dispatch = useDispatch();
  const {
    account,
    nodes,
    sessionToken,
  } = useSelector(({ appState }: RootState) => appState);

  useEffect(() => {
    const encodedSessionToken = localStorage.getItem(localStorageKeys.SESSION_TOKEN);
    if(encodedSessionToken) {
      try {
        const sessionToken: SessionToken = JSON.parse(encodedSessionToken);
        const now = dayjs();
        if(dayjs(sessionToken.expiration).isAfter(now))
          dispatch(setSessionToken({sessionToken}));
      } catch(err) {
        handleError(err);
      }
    }
  }, [dispatch]);

  useEffect(() => {
    if(sessionToken) {
      if(!account || account.id !== sessionToken.user) {
        ccAPI.account(sessionToken)
          .then(account => {
            dispatch(setAccount({account}));
          })
          .catch(err => {
            handleError(err);
          });
      }
    } else if(account) {
      dispatch(setAccount({account: null}));
    }
  }, [dispatch, sessionToken, account, ccAPI]);

  useEffect(() => {

    let closed = false;
    let updateDataInterval: NodeJS.Timer;

    if(sessionToken && account && account.id === sessionToken.user) {

      const updateData = (): void => {

        ccAPI.chains(sessionToken)
          .then(chains => {
            dispatch(setChains({chains}));
          })
          .catch(err => {
            handleError(err);
            if(!closed)
              dispatch(setChains({chains: []}));
          });

        ccAPI.nodes(sessionToken)
          .then(nodes => {
            if(!closed)
              dispatch(setNodes({nodes}));
          })
          .catch(err => {
            handleError(err);
            dispatch(setNodes({nodes: []}));
          });

        ccAPI.accountBalance(sessionToken)
          .then(balance => {
            if(!closed)
              dispatch(setBalance({balance}));
          })
          .catch(err => {
            handleError(err);
          });

        // ccAPI.account(sessionToken)
        //   .then(account => {
        //     if(!closed)
        //       dispatch(setAccount({account}));
        //   })
        //   .catch(err => {
        //     handleError(err);
        //   });

      };
      updateDataInterval = setInterval(() => {
        updateData();
      }, 60000);
      updateData();
    } else {
      dispatch(setChains({chains: []}));
      dispatch(setNodes({nodes: []}));
      dispatch(setBalance({balance: ''}));
    }
    return () => {
      closed = true;
      if(updateDataInterval)
        clearInterval(updateDataInterval);
    };
  }, [dispatch, ccAPI, sessionToken, account]);

  useEffect(() => {
    if(sessionToken && account && account.id === sessionToken.user) {
      ccAPI.queryPoktNodes(sessionToken, nodes.map(n => n.address))
        .then(poktQueryData => {
          dispatch(setPoktQueryData({poktQueryData}))
        })
        .catch(err => {
          console.error(err);
        });
    }
  }, [account, dispatch, nodes, sessionToken, ccAPI]);

  return (
    <AppContainer>
      <RouterProvider router={router} />
    </AppContainer>
  );
}

export default App;
