import React from 'react';
import { Login } from './login';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { BodyContainer } from './ui/body-container';

export const Support = () => {

  const {
    account,
    sessionToken,
  } = useSelector(({ appState }: RootState) => appState);

  const styles = {
    container: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
  };

  if(!sessionToken && !account)
    return <Login />;
  else if(!account)
    return <div />;

  return (
    <BodyContainer>
      <div style={styles.container as React.CSSProperties}>
        <div className={'container'}>
          <h1>Support</h1>
          <p>For help and support, please visit us on <a href={'https://discord.com/invite/W9CrDBB44U'} title={'Discord'}>Discord</a>.</p>
        </div>
      </div>
    </BodyContainer>
  );
};
