import React from 'react';
import { useDispatch } from 'react-redux';
import { setAccount, setSessionToken } from '../../reducers/app-reducer';
import { localStorageKeys, routes } from '../../constants';
import { Link } from 'react-router-dom';
import ccLogoText from '../../images/cc_logo_text.svg';

export const Navbar = () => {

  const dispatch = useDispatch();

  const styles = {
    logoImage: {
      height: 40,
      marginLeft: -4,
      marginTop: -5,
      marginBottom: -5,
    },
    navLink: {
      fontSize: 30,
      paddingTop: 0,
      paddingBottom: 0,
      lineHeight: '41px',
    },
    navLinkText: {
      fontSize: 18,
      paddingTop: 6,
      paddingBottom: 6,
      lineHeight: '29px',
    },
  };

  const onLogoutClick = async (e: React.MouseEvent) => {
    e.preventDefault();
    localStorage.removeItem(localStorageKeys.SESSION_TOKEN);
    dispatch(setAccount({account: null}));
    dispatch(setSessionToken({sessionToken: null}));
  };

  return (
    <nav className={'navbar navbar-expand-lg navbar-dark navbar-custom-dark'}>
      {/*<a className="navbar-brand" href="/">Community Chains</a>*/}
      <a className="navbar-brand" href="/">
        <img style={styles.logoImage} alt={'Community Chains'} src={ccLogoText} />
      </a>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon" />
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ml-auto">

          <li className={'nav-item'}>
            <a href={'https://docs.decentralizedauthority.com/community-chains'} style={styles.navLinkText} className="nav-link text-info mr-2" title={'Getting Started Docs'}>Getting Started Docs</a>
          </li>

          <li className={'nav-item'}>
            <Link style={styles.navLink} className="nav-link" to={routes.HOME} title={'Home'}><i className={'mdi mdi-home'} /></Link>
          </li>

          <li className={'nav-item'}>
            <Link style={styles.navLink} className="nav-link" to={routes.RELAY_INVOICES} title={'Relay Invoices'}><i className={'mdi mdi-file-table'} /></Link>
          </li>

          <li className={'nav-item'}>
            <Link style={styles.navLink} className="nav-link" to={routes.USER_SETTINGS} title={'User Settings'}><i className={'mdi mdi-account-cog'} /></Link>
          </li>

          <li className={'nav-item'}>
            <Link style={styles.navLink} className="nav-link" to={routes.DOCS} title={'API Documentation'}><i className={'mdi mdi-note-text'} /></Link>
          </li>

          <li className="nav-item">
            <Link style={styles.navLink} className="nav-link" to={routes.SUPPORT} title={'Support'}><i className={'mdi mdi-help-circle'} /></Link>
          </li>

          <li className="nav-item">
            <a style={styles.navLink} className="nav-link" href={'#'} title={'Log out'} onClick={onLogoutClick}><i className={'mdi mdi-logout-variant'} /></a>
          </li>

        </ul>
      </div>
    </nav>
  );
};
