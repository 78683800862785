import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { CCApiContext } from '../../hooks/cc-api-hook';
import { Map, Set } from 'immutable';
import swal from 'sweetalert';
import { handleError, shortenAddress } from '../../util';
import { setNodes } from '../../reducers/app-reducer';
import { Chain } from '../../interfaces';
import dayjs from 'dayjs';
import isString from 'lodash/isString';
import isNumber from 'lodash/isString';
import escapeRegExp from 'lodash/escapeRegExp';
// @ts-ignore
import { saveAs } from 'browser-filesaver';

export interface NodesTableProps {
  sortBy: string
  sortDirection: number
  filterBy: string
  filter: string
  onSortByChange: (sortBy: string)=>void
  onSelect: (addresses: string[]) => void
}

export const NodesTable = ({ sortBy, sortDirection, filterBy, filter, onSortByChange, onSelect }: NodesTableProps) => {

  const dispatch = useDispatch();
  const ccAPI = useContext(CCApiContext);
  const {
    chains,
    nodes,
    poktQueryData,
    sessionToken,
  } = useSelector(({ appState }: RootState) => appState);

  const [ selectedNodes, setSelectedNodes ] = useState<Set<string>>(Set());
  const [ chainsMap, setChainsMap ] = useState<Map<string, Chain>>(Map());

  useEffect(() => {
    setChainsMap(Map(chains.map(c => [c.id, c])));
  }, [chains]);

  useEffect(() => {
    onSelect(selectedNodes.toArray());
  }, [selectedNodes]);
  useEffect(() => {
    const removed = selectedNodes.toArray()
      .filter(a => !nodes.find(n => n.address === a));
    if(removed.length > 0) {
      const newSelected = removed
        .reduce((set, a) => {
          return set.remove(a);
        }, selectedNodes);
      setSelectedNodes(Set(newSelected));
    }
  }, [selectedNodes, nodes]);

  const styles = {
    checkboxHeader: {
      fontSize: 18,
      lineHeight: '20px',
    },
    checkbox: {
      fontSize: 18,
      lineHeight: '20px',
    },
    downloadHeader: {
      paddingTop: 0,
      paddingBottom: 6,
      fontSize: 20,
      lineHeight: '20px',
    },
    download: {
      paddingTop: 9,
      paddingBottom: 0,
      fontSize: 20,
      lineHeight: '20px',
    },
  };

  const onDeleteNodeClick = async (e: React.MouseEvent, address: string) => {
    e.preventDefault();
    if(!sessionToken)
      return;
    const confirmed = await swal({
      icon: 'warning',
      title: 'Delete node',
      text: `Are you sure that you want to delete this node?\n\n${address}`,
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Delete node',
          className: 'bg-danger',
          visible: true,
          closeModal: false,
        },
      },
    });
    if(!confirmed)
      return;
    try {
      const success = await ccAPI.nodeDelete(sessionToken, address);
      if(!success)
        throw new Error('Unable to delete node.');
      dispatch(setNodes({nodes: nodes.filter(n => n.address !== address)}));
      await swal({
        icon: 'success',
        title: 'Success',
        text: 'Node successfully deleted.',
      });
    } catch(err: any) {
      handleError(err);
      await swal({
        icon: 'error',
        title: 'Oops!',
        text: err.message,
      });
    }
  };
  const onSelectAllClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if(selectedNodes.size === nodes.length) {
      setSelectedNodes(Set());
    } else {
      setSelectedNodes(Set(nodes.map(n => n.address)));
    }
  };
  const onSelectClick = (e: React.MouseEvent, address: string) => {
    e.preventDefault();
    if(selectedNodes.has(address)) {
      setSelectedNodes(selectedNodes.remove(address));
    } else {
      setSelectedNodes(selectedNodes.add(address));
    }
  };
  const onHeaderClick = (e: React.MouseEvent, sortKey: string) => {
    e.preventDefault();
    onSortByChange(sortKey);
  };
  let filterPatt: RegExp;
  if(filter) {
    if(filterBy === 'address') {
      filterPatt = new RegExp(`^${escapeRegExp(filter)}`, 'i');
    } else {
      filterPatt = new RegExp(escapeRegExp(filter), 'i');
    }
  }

  return (
    <table className={'table table-bordered table-sm mb-2'}>
      <thead>
      <tr>
        <th className={'text-center'} style={styles.checkboxHeader}><a href={'#'} title={'Select/Unselect all'} onClick={onSelectAllClick}><i className={`mdi ${selectedNodes.size === nodes.length ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank'}`} /></a></th>
        <th><a href={'#'} onClick={e => onHeaderClick(e, 'createdAt')}>Date Added</a></th>
        <th><a href={'#'} onClick={e => onHeaderClick(e, 'address')}>Address</a></th>
        <th><a href={'#'} onClick={e => onHeaderClick(e, 'service_url')}>Service URL</a></th>
      </tr>
      </thead>
      <tbody>
      {[...nodes]
        .filter(n => {
          if(filterPatt) {
            let value: string;
            if(filterBy === 'service_url') {
              // @ts-ignore
              value = poktQueryData[n.address] ? poktQueryData[n.address].service_url : '';
            } else {
              // @ts-ignore
              value = n[filterBy] || '';
            }
            return filterPatt.test(value);
          } else {
            return true;
          }
        })
        .sort((a, b) => {
          if(sortBy === 'service_url') {
            const queryDataA = poktQueryData[a.address];
            const queryDataB = poktQueryData[b.address];
            if(queryDataA && queryDataB) {
              const serviceUrlA: string = queryDataA.service_url || '';
              const serviceUrlB: string = queryDataB.service_url || '';
              return serviceUrlA.localeCompare(serviceUrlB) * sortDirection;
            } else {
              return 0;
            }
          } else {
            // @ts-ignore
            const valA = a[sortBy];
            // @ts-ignore
            const valB = b[sortBy];
            if(isNumber(valA) && isNumber(valB)) {
              return (valA === valB ? 0 : valA > valB ? 1 : -1) * sortDirection;
            } else if(isString(valA) && isString(valB)) {
              return valA.localeCompare(valB) * sortDirection;
            } else {
              return 0;
            }
          }
        })
        .map(n => {
          const queryData = poktQueryData[n.address];
          return (
            <tr key={n.address}>
              <td className={'text-center'} style={styles.checkbox}><a href={'#'} onClick={e => onSelectClick(e, n.address)}><i className={`mdi ${selectedNodes.has(n.address) ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank'}`} /></a></td>
              <td className={'text-monospace'} title={new Date(n.createdAt).toLocaleString()}>{dayjs(n.createdAt).format('YYYY-MM-DD')}</td>
              <td className={'text-monospace'}><a href={`https://poktscan.com/node/${n.address}`}>{n.address}</a></td>
              <td>{queryData ? queryData.service_url : ''}</td>
            </tr>
          )
        })
      }
      </tbody>
    </table>
  );
};
