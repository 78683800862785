import React, { useContext, useEffect } from 'react';
import { Login } from './login';
import { CCApiContext } from '../hooks/cc-api-hook';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { BodyContainer } from './ui/body-container';
import { Sidebar } from './ui/sidebar';
import { FlexRow } from './ui/flex';
import { handleError, shortenAddress } from '../util';
import { setRelayInvoices } from '../reducers/app-reducer';
import * as math from 'mathjs';

export const RelayInvoices = () => {

  const dispatch = useDispatch();
  const ccAPI = useContext(CCApiContext);
  const {
    account,
    relayInvoices,
    relayInvoicesCount,
    sessionToken,
  } = useSelector(({ appState }: RootState) => appState);

  useEffect(() => {
    if(sessionToken) {
      ccAPI.accountRelayInvoices(sessionToken, relayInvoicesCount)
        .then((res) => {
          dispatch(setRelayInvoices({relayInvoices: res}));
        })
        .catch((err) => {
          handleError(err);
        });
    }
  }, [ccAPI, relayInvoicesCount, sessionToken]);

  const styles = {
    container: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
    mainColumn: {
      flexGrow: 1,
      position: 'relative',
    },
    mainColumnInnerContainer: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      overflow: 'auto',
    },
  };

  const calculateTotalRewards = (relays: string, sessionRelays: string, sessionRewards: string): number => {
    const res = math.multiply(
      math.divide(
        math.bignumber(sessionRewards),
        math.bignumber(sessionRelays)
      ),
      math.bignumber(relays),
    ).toString();
    return Math.floor(Number(res));
  };

  if(!sessionToken && !account)
    return <Login />;
  else if(!account)
    return <div />;

  return (
    <BodyContainer>
      <div className={'d-flex flex-row justify-content-start align-items-stretch'} style={styles.container as React.CSSProperties}>
        <Sidebar />
        <div style={styles.mainColumn as React.CSSProperties}>
          <div style={styles.mainColumnInnerContainer as React.CSSProperties}>
            <div className={'container-fluid'}>
              <FlexRow justifyContent={'flex-start'}>
                <h2>Relay Invoices</h2>
              </FlexRow>
              <table className={'table table-bordered table-sm mb-2'}>
                <thead>
                <tr>
                  <th>Status</th>
                  <th>Date</th>
                  <th>Total Fee<a href={'#'} title={'Fee deducted by Community Chains from the user\'s shared wallet to pay for relays served.'}>*</a></th>
                  <th>TXID</th>
                  <th>Fee Breakdown</th>
                </tr>
                </thead>
                <tbody>
                {relayInvoices
                  .map((invoice) => {
                    return (
                      <tr key={invoice.id}>
                        <td className={invoice.txid ? 'text-success' : 'text-danger'}>{invoice.txid ? 'PAID' : 'IN PROCESS'}</td>
                        <td>{new Date(invoice.date).toLocaleString()}</td>
                        <td className={'text-monospace'}>{(Number(invoice.total) / 1000000).toFixed(6)} POKT</td>
                        <td className={'text-monospace'}>{invoice.txid ? <a href={`https://poktscan.com/tx/${invoice.txid}`}>{shortenAddress(invoice.txid)}</a> : ''}</td>
                        <td>
                          <table className={'text-monospace'}>
                            <thead>
                              <tr>
                                <th>Chain</th>
                                <th>Session Relays<a href={'#'} title={'Total number of relays recorded for registered nodes during billing period in claims/proofs on the POKT blockchain.'}>*</a></th>
                                <th>Session Rewards<a href={'#'} title={'Total amount of rewards generated by registered nodes during billing period in claims/proofs on the POKT blockchain.'}>*</a></th>
                                <th>CC Relays<a href={'#'} title={'Number of relays handled by Community Chains during billing period.'}>*</a></th>
                                <th>CC Rewards<a href={'#'} title={'Calculated total amount of rewards generated by relays handled by Community Chains during billing period.'}>*</a></th>
                                <th>Fee %<a href={'#'} title={'Chain-specific fee percentage taken from rewards generated by relays handled by Community Chains.'}>*</a></th>
                                <th>CC Fee<a href={'#'} title={'Calculated fee for relays served.'}>*</a></th>
                              </tr>
                            </thead>
                            <tbody>
                            {[...invoice.relays]
                              .sort((a, b) => Number(b.rewardsAmt) - Number(a.rewardsAmt))
                              .map((invoiceRelays) => {
                                return (
                                  <tr>
                                    <td>{invoiceRelays.chain.toUpperCase()}</td>
                                    <td>{invoiceRelays.sessionRelays}</td>
                                    <td>{(Number(invoiceRelays.sessionRewards) / 1000000).toFixed(6)} POKT</td>
                                    <td>{invoiceRelays.relays}</td>
                                    <td>{(calculateTotalRewards(invoiceRelays.relays, invoiceRelays.sessionRelays, invoiceRelays.sessionRewards) / 1000000).toFixed(6)} POKT</td>
                                    <td>{invoiceRelays.rewardsPerc * 100}%</td>
                                    <td>{(Number(invoiceRelays.rewardsAmt) / 1000000).toFixed(6)} POKT</td>
                                  </tr>
                                );
                              })
                            }
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    );
                  })
                }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </BodyContainer>
  );
};
