import React, { useContext, useState } from 'react';
import { Login } from './login';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { BodyContainer } from './ui/body-container';
import { NodesTable } from './ui/nodes-table';
import { FlexColumn, FlexRow } from './ui/flex';
import { Link, useNavigate } from 'react-router-dom';
import { routes } from '../constants';
import swal from 'sweetalert';
import { setNodes } from '../reducers/app-reducer';
import { handleError } from '../util';
import { CCApiContext } from '../hooks/cc-api-hook';
import { Sidebar } from './ui/sidebar';

export const Home = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ccAPI = useContext(CCApiContext);
  const {
    account,
    nodes,
    sessionToken,
  } = useSelector(({ appState }: RootState) => appState);
  const [ showFilter, setShowFilter ] = useState(false);
  const [ selectedNodes, setSelectedNodes ] = useState<string[]>([]);
  const queryParams = new URLSearchParams(window.location.search);
  const sortBy = queryParams.get('sortby') || 'createdAt';
  const sortDirectionStr = queryParams.get('direction') || -1;
  const sortDirection = Number(sortDirectionStr);
  const filterBy = queryParams.get('filterby') || 'address';
  const filter = queryParams.get('filter') || '';

  const styles = {
    container: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
    mainColumn: {
      flexGrow: 1,
      position: 'relative',
    },
    mainColumnInnerContainer: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      overflow: 'auto',
    },
    filterSelect: {
      width: 200,
    },
  };

  const onDeleteNodesClick = async (e: React.MouseEvent) => {
    e.preventDefault();
    if(!sessionToken)
      return;
    const confirmed = await swal({
      icon: 'warning',
      title: selectedNodes.length > 1 ? 'Delete nodes' : 'Delete node',
      text: `Are you sure that you want to delete ${selectedNodes.length > 1 ? `these ${selectedNodes.length} nodes` : 'this node'}? Once deleted, a node cannot be added back into the system until at least twenty-four hours has passed.`,
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: selectedNodes.length > 1 ? 'Delete nodes' : 'Delete node',
          className: 'bg-danger',
          visible: true,
          closeModal: false,
        },
      },
    });
    if(!confirmed)
      return;
    try {
      await Promise.all(selectedNodes.map(address => ccAPI.nodeDelete(sessionToken, address)))
      dispatch(setNodes({nodes: nodes.filter(n => !selectedNodes.includes(n.address))}));
      await swal({
        icon: 'success',
        title: 'Success',
        text: `Node${selectedNodes.length > 1 ? 's' : ''} successfully deleted.`,
      });
    } catch(err: any) {
      handleError(err);
      await swal({
        icon: 'error',
        title: 'Oops!',
        text: err.message,
      });
    }
  };
  const onSortByChange = (sortKey: string) => {
    let newSortBy: string;
    let newSortDirection: number;
    if(sortKey === sortBy) {
      newSortBy = sortBy;
      newSortDirection = sortDirection * -1;
    } else {
      newSortBy = sortKey;
      if(sortKey === 'createdAt') {
        newSortDirection = -1;
      } else {
        newSortDirection = 1;
      }
    }
    navigate(`/?sortby=${newSortBy}&direction=${newSortDirection}&filterby=${filterBy}&filter=${filter}`);
  };
  const onToggleFilterClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setShowFilter(!showFilter);
    if(showFilter)
      navigate(`/?sortby=${sortBy}&direction=${sortDirection}&filterby=${filterBy}&filter=`);
  };
  const onFilterByChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    navigate(`/?sortby=${sortBy}&direction=${sortDirection}&filterby=${e.target.value}&filter=`);
  };
  const onFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    navigate(`/?sortby=${sortBy}&direction=${sortDirection}&filterby=${filterBy}&filter=${e.target.value}`);
  };

  if(!sessionToken && !account)
    return <Login />;
  else if(!account)
    return <div />;

  return (
    <BodyContainer>
      <div className={'d-flex flex-row justify-content-start align-items-stretch'} style={styles.container as React.CSSProperties}>
        <Sidebar />
        <div style={styles.mainColumn as React.CSSProperties}>
          <div style={styles.mainColumnInnerContainer as React.CSSProperties}>
            <div className={'container-fluid'}>
              <FlexRow justifyContent={'flex-start'}>
                <h2>Registered Nodes <a href={'#'} onClick={onToggleFilterClick}><i className={'mdi mdi-magnify'} /></a></h2>
                <div className={'flex-grow-1'} />
                {nodes.length > 0 ?
                  <FlexColumn justifyContent={'center'}>
                    <span className={'mr-3'}>{nodes.length} {nodes.length === 1 ? 'node' : 'nodes'} registered.</span>
                  </FlexColumn>
                  :
                  null
                }
                <FlexColumn justifyContent={'center'}>
                  {selectedNodes.length > 0 ?
                    <FlexRow justifyContent={'flex-start'}>
                      <a className={'btn btn-danger btn-sm'} href={'#'} onClick={onDeleteNodesClick}><i className={'mdi mdi-trash-can-outline'} /> Delete {selectedNodes.length} Node(s)</a>
                    </FlexRow>
                    :
                    <Link className={'btn btn-primary btn-sm'} to={routes.ADD_NODES}><i className={'mdi mdi-plus'} /> Add Node(s)</Link>
                  }
                </FlexColumn>
              </FlexRow>
              {showFilter ?
                <FlexRow justifyContent={'flex-start'}>
                  <div className={'form-group mr-1'}>
                    <label>Filter By:</label>
                    <select style={styles.filterSelect} className={'form-control'} value={filterBy} onChange={onFilterByChange}>
                      <option value={'address'}>Address</option>
                      <option value={'service_url'}>Service URL</option>
                    </select>
                  </div>
                  <div className={'form-group flex-grow-1'}>
                    <label>Filter Value:</label>
                    <input className={'form-control'} type={'text'} placeholder={'Enter filter value'} value={filter} onChange={onFilterChange} />
                  </div>
                </FlexRow>
                :
                null
              }
              <NodesTable sortBy={sortBy} onSortByChange={onSortByChange} filterBy={filterBy} filter={showFilter ? filter : ''} sortDirection={sortDirection} onSelect={addresses => setSelectedNodes(addresses)} />
            </div>
          </div>
        </div>
      </div>
    </BodyContainer>
  );
};
