import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Account, Chain, Node, RelayInvoice, SessionToken } from '../interfaces';
import { PoktQueryNodeResponse } from '../modules/cc-api-client';

export interface AppState {
  locale: string
  windowWidth: number
  windowHeight: number
  sessionToken: SessionToken|null,
  account: Account|null
  chains: Chain[]
  nodes: Node[]
  relayInvoicesCount: number
  relayInvoices: RelayInvoice[]
  balance: string
  poktQueryData: {[address: string]: PoktQueryNodeResponse|null}
}

const getInitialState = (): AppState => ({
  locale: 'en-US',
  windowWidth: window.innerWidth,
  windowHeight: window.innerHeight,
  sessionToken: null,
  account: null,
  chains: [],
  nodes: [],
  relayInvoicesCount: 20,
  relayInvoices: [],
  balance: '',
  poktQueryData: {},
});

export const appSlice = createSlice({
  name: 'appState',
  initialState: getInitialState(),
  reducers: {
    setWindowSize: (state, action: PayloadAction<{innerWidth: number, innerHeight: number}>) => {
      state.windowHeight = action.payload.innerHeight;
      state.windowWidth = action.payload.innerWidth;
    },
    setLocale: (state, action: PayloadAction<{locale: string}>) => {
      state.locale = action.payload.locale;
    },
    setSessionToken: (state, action: PayloadAction<{sessionToken: SessionToken|null}>) => {
      state.sessionToken = action.payload.sessionToken;
    },
    setAccount: (state, action: PayloadAction<{account: Account|null}>) => {
      state.account = action.payload.account;
    },
    setChains: (state, action: PayloadAction<{chains: Chain[]}>) => {
      state.chains = action.payload.chains;
    },
    setNodes: (state, action: PayloadAction<{nodes: Node[]}>) => {
      state.nodes = action.payload.nodes;
    },
    setRelayInvoicesCount: (state, action: PayloadAction<{relayInvoicesCount: number}>) => {
      state.relayInvoicesCount = action.payload.relayInvoicesCount;
    },
    setRelayInvoices: (state, action: PayloadAction<{relayInvoices: RelayInvoice[]}>) => {
      state.relayInvoices = action.payload.relayInvoices;
    },
    setBalance: (state, action: PayloadAction<{balance: string}>) => {
      state.balance = action.payload.balance;
    },
    setPoktQueryData: (state, action: PayloadAction<{poktQueryData: {[address: string]: PoktQueryNodeResponse|null}}>) => {
      state.poktQueryData = action.payload.poktQueryData;
    },
  }
});

export const { setWindowSize, setLocale, setSessionToken, setAccount, setChains, setNodes, setRelayInvoices, setRelayInvoicesCount, setBalance, setPoktQueryData } = appSlice.actions;

export default appSlice.reducer;
