import React, { useContext, useState } from 'react';
import { Card, CardBody, CardHeader } from './card';
import { FlexRow } from './flex';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { CCApiContext } from '../../hooks/cc-api-hook';
import { generateEndpointUrl, handleError } from '../../util';
import { Account, Chain, ChainHost } from '../../interfaces';
import { setAccount } from '../../reducers/app-reducer';
// @ts-ignore
import { saveAs } from 'browser-filesaver';

const shortenRegion = (region: string): string => {
  return region.split('-')[0] || region;
};
const regionDisplayName = (region: string): string => {
  switch(region) {
    case 'us-east-1':
      return 'US East';
    case 'ap-southeast-1':
      return 'Asia Pacific Southeast';
    case 'eu-central-1':
      return 'EU Central';
    default:
      return region;
  }
};

export const ChainsCard = () => {

  const [ selectedTab, setSelectedTab ] = useState<number>(0);

  const {
    account,
    chains,
    balance,
    sessionToken
  } = useSelector(({ appState }: RootState) => appState);
  const ccAPI = useContext(CCApiContext);
  const dispatch = useDispatch();

  const styles = {
    card: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
    listContainer: {
      position: 'relative',
    },
    list: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      overflow: 'auto',
      borderRadius: '0px 0px 0px 0px',
    },
    toggleIcon: {
      marginTop: -12,
      marginBottom: -12,
      fontSize: 30,
    },
    downloadIcon: {
      marginTop: -10,
      marginBottom: -10,
      fontSize: 20,
    },
    copyIcon: {
      marginTop: -12,
      marginBottom: -12,
      fontSize: 20,
    },
    chainCardBody: {
      position: 'relative',
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 0,
    },
    chainCardInnerBody: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: '1em',
      bottom: 0,
    },
  };

  const onTabClick = (e: React.MouseEvent, idx: number) => {
    e.preventDefault();
    setSelectedTab(idx);
  };

  if(!account)
    return <div></div>;

  const onToggleChainClick = async (e: React.MouseEvent, chainId: string) => {
    e.preventDefault();
    if(!sessionToken)
      return;
    try {
      const selected = account.chains.some((c) => c.id === chainId);
      let res: ChainHost[];
      if(selected) {
        const newChains = account.chains
          .filter((c) => c.id !== chainId)
          .map((c) => c.id);
        res = await ccAPI.accountUpdateChains(sessionToken, newChains);
        console.log('res', res);
      } else {
        const newChains = [
          ...account.chains.map((c) => c.id),
          chainId,
        ];
        res = await ccAPI.accountUpdateChains(sessionToken, newChains);
      }
      const newAccount = {
        ...account,
        chains: res,
      };
      dispatch(setAccount({account: newAccount}));
    } catch (err: any) {
      handleError(err);
    }
  };
  const handleDownloadChainsClick = (e: React.MouseEvent) => {
    e.preventDefault();
    const chainsArr = [...account.chains]
      .sort((a, b) => a.id.localeCompare(b.id))
      .map(c => ({
        id: c.id.toUpperCase(),
        url: generateEndpointUrl(c.host),
        basic_auth: {
          username: '',
          password: '',
        },
      }));
    const chainsJson = JSON.stringify(chainsArr, null, '  ');
    const blob = new Blob([chainsJson], {type: 'application/json;charset=utf-8'});
    saveAs(blob, 'chains.json');
  };
  const handleCopyEndpointClick = (e: React.MouseEvent, chainId: string) => {
    e.preventDefault();
    const chain = account.chains.find((c) => c.id === chainId);
    if(!chain)
      return;
    navigator.clipboard.writeText(generateEndpointUrl(chain.host))
      .catch(handleError);
  };
  const selectedChains = account.chains
    .map((c) => c.id)
    .reduce((acc, id) => {
      return  {
        ...acc,
        [id]: true,
      };
    }, {} as {[key: string]: boolean});

  let multiProviderChains: Chain[] = [];
  let singleProviderChains: Chain[] = [];
  for(const chain of chains) {
    const regions = Object.keys(chain.providers);
    if(regions.length > 1 || (regions.length === 1 && chain.providers[regions[0]] > 1)) {
      multiProviderChains.push(chain);
    } else {
      singleProviderChains.push(chain);
    }
  }
  const multiProviderSelectedChains = multiProviderChains
    .reduce((acc, chain) => {
      return account.chains.some((c) => c.id === chain.id) ? acc + 1 : acc;
    }, 0);
  const singleProviderSelectedChains = singleProviderChains
    .reduce((acc, chain) => {
      return account.chains.some((c) => c.id === chain.id) ? acc + 1 : acc;
    }, 0);

  return (
    <Card style={styles.card as React.CSSProperties}>
      <CardHeader>
        <h4>
          <FlexRow justifyContent={'space-between'} wrap={'nowrap'} alignItems={'center'}>
            <span>Selected Chains <span className={'text-muted'}>({account.chains.length})</span></span>
            <a style={styles.downloadIcon} href={'#'} title={'Download chains.json'} onClick={handleDownloadChainsClick}><i className={'mdi mdi-download'} /></a>
          </FlexRow>
        </h4>
      </CardHeader>
      <CardBody style={styles.chainCardBody as React.CSSProperties}>
        <div style={styles.chainCardInnerBody as React.CSSProperties} className={'d-flex flex-column justify-content-start'}>
          <ul className={'nav nav-tabs'}>
            <li className={'nav-item flex-grow-1'}>
              <a className={`nav-link ${selectedTab === 0 ? 'active' : ''}`} href={'#'} onClick={e => onTabClick(e, 0)}>Multi-Provider <span className={'text-muted'}>({multiProviderSelectedChains}/{multiProviderChains.length})</span></a>
            </li>
            <li className={'nav-item flex-grow-1'}>
              <a className={`nav-link ${selectedTab === 1 ? 'active' : ''}`} href={'#'} onClick={e => onTabClick(e, 1)}>Single-Provider <span className={'text-muted'}>({singleProviderSelectedChains}/{singleProviderChains.length})</span></a>
            </li>
          </ul>
          <div style={styles.listContainer as React.CSSProperties} className={'flex-grow-1'}>
            <ul style={styles.list as React.CSSProperties} className={'list-group'}>
              {(selectedTab === 0 ? [...multiProviderChains] : [...singleProviderChains])
                .sort((a, b) => {
                  const aSelected = selectedChains[a.id];
                  const bSelected = selectedChains[b.id];
                  if(aSelected === bSelected) {
                    return a.id.localeCompare(b.id);
                  } else {
                    return aSelected ? -1 : 1;
                  }
                })
                .map((chain, i) => {
                  const selected = account.chains.some((c) => c.id === chain.id);
                  return (
                    <li key={i} className={'list-group-item'}>
                      <FlexRow justifyContent={'flex-start'} wrap={'nowrap'} alignItems={'center'} gap={'1em'}>
                        <a onClick={(e) => onToggleChainClick(e, chain.id)} className={selected ? 'text-success' : 'text-danger'} style={styles.toggleIcon} href={'#'}><i className={`mdi ${selected ? 'mdi-toggle-switch' : 'mdi-toggle-switch-off'}`} /></a>
                        <span className={'text-monospace'}>{chain.id.toUpperCase()}</span>
                        <span className={'flex-grow-1'}>{chain.name}</span>
                        <span className={'text-monospace mr-1'}>{
                          Object.keys(chain.providers)
                            .sort((a, b) => a.localeCompare(b))
                            .map((r, i) => {
                              return (
                                <span key={`${chain.id}-${r}`} title={regionDisplayName(r)}>{`${shortenRegion(r).toUpperCase()}${i < Object.keys(chain.providers).length - 1 ? ',' : ''}`}</span>
                              );
                            })
                        }</span>
                        {selected ? <a title={'Copy endpoint'} style={styles.copyIcon} href={'#'} onClick={(e) => handleCopyEndpointClick(e, chain.id)}><i className={'mdi mdi-content-copy'} /></a> : <span style={{width: 20}}></span>}
                      </FlexRow>
                    </li>
                  )
                })}
            </ul>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
