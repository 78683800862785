import request from 'superagent';
import { Account, Chain, ChainHost, DocsObj, Node, RelayInvoice, SessionToken } from '../interfaces';
import isString from 'lodash/isString';

export interface PoktQueryNodeResponse {
  address: string
  chains: string[]
  jailed: boolean
  public_key: string
  service_url: string
  status: number
  tokens: string
  unstaking_time: string
}

export class CCApiClient {

  _endpoint: string;
  _timeout = 30000;

  constructor(endpoint: string) {
    this._endpoint = endpoint;
  }

  async _makeRequest(requestFunc: ()=>Promise<request.Response>): Promise<request.Response> {
    try {
      return await requestFunc();
    } catch(err: any) {
      const body = err?.response?.body;
      if(body && isString(body)) {
        throw new Error(body);
      } else {
        throw err;
      }
    }
  }

  async unlock(email: string, password: string): Promise<SessionToken> {
    const { body } = await this._makeRequest(() => request
      .post(`${this._endpoint}/v1/unlock`)
      .type('application/json')
      .timeout(this._timeout)
      .send({
        email,
        password,
      }));
    return body;
  }

  async queryPoktNodes(sessionToken: SessionToken, addresses: string[]): Promise<{[address: string]: PoktQueryNodeResponse|null}> {
    const { body } = await this._makeRequest(() => request
      .post(`${this._endpoint}/v1/query-pokt-nodes`)
      .set({'x-api-key': sessionToken.token})
      .type('application/json')
      .timeout(this._timeout)
      .send({
        addresses,
      }));
    return body;
  }

  async account(sessionToken: SessionToken): Promise<Account> {
    const { body } = await this._makeRequest(() => request
      .get(`${this._endpoint}/v1/accounts/${sessionToken.user}`)
      .set({'x-api-key': sessionToken.token})
      .type('application/json')
      .timeout(this._timeout));
    return body;
  }

  async accountPrivateKey(sessionToken: SessionToken, password: string): Promise<string> {
    const { body } = await this._makeRequest(() => request
        .post(`${this._endpoint}/v1/accounts/${sessionToken.user}/private-key`)
        .set({'x-api-key': sessionToken.token})
        .type('application/json')
        .send({password})
        .timeout(this._timeout));
    return body;
  }

  async accountBalance(sessionToken: SessionToken): Promise<string> {
    const { body } = await this._makeRequest(() => request
      .get(`${this._endpoint}/v1/accounts/${sessionToken.user}/balance`)
      .set({'x-api-key': sessionToken.token})
      .type('application/json')
      .timeout(this._timeout));
    return body;
  }

  async accountUpdateEmail(sessionToken: SessionToken, email: string, recaptchaToken: string): Promise<boolean> {
    const { body } = await this._makeRequest(() => request
      .post(`${this._endpoint}/v1/accounts/${sessionToken.user}/update-email`)
      .set({'x-api-key': sessionToken.token})
      .type('application/json')
      .timeout(this._timeout)
      .send({
        email: email.trim().toLowerCase(),
        recaptchaToken,
      }));
    return body;
  }

  async accountUpdatePassword(sessionToken: SessionToken, currentPassword: string, newPassword: string): Promise<boolean> {
    const { body } = await this._makeRequest(() => request
      .post(`${this._endpoint}/v1/accounts/${sessionToken.user}/update-password`)
      .set({'x-api-key': sessionToken.token})
      .type('application/json')
      .timeout(this._timeout)
      .send({
        currentPassword,
        newPassword,
      }));
    return body;
  }

  async accountUpdateChains(sessionToken: SessionToken, chains: string[]): Promise<ChainHost[]> {
    const { body } = await this._makeRequest(() => request
      .post(`${this._endpoint}/v1/accounts/${sessionToken.user}/update-chains`)
      .set({'x-api-key': sessionToken.token})
      .type('application/json')
      .timeout(this._timeout)
      .send({chains}));
    return body;
  }

  async accountRelayInvoices(sessionToken: SessionToken, count: number): Promise<RelayInvoice[]> {
    const { body } = await this._makeRequest(() => request
      .post(`${this._endpoint}/v1/accounts/${sessionToken.user}/relay-invoices`)
      .set({'x-api-key': sessionToken.token})
      .type('application/json')
      .timeout(this._timeout)
      .send({count}));
    return body;
  }

  async accountDelete(sessionToken: SessionToken, password: string): Promise<boolean> {
    const { body } = await this._makeRequest(() => request
      .post(`${this._endpoint}/v1/accounts/${sessionToken.user}/delete`)
      .set({'x-api-key': sessionToken.token})
      .type('application/json')
      .timeout(this._timeout)
      .send({
        password,
      }));
    return body;
  }

  async chains(sessionToken: SessionToken): Promise<Chain[]> {
    const { body } = await this._makeRequest(() => request
      .get(`${this._endpoint}/v1/chains`)
      .set({'x-api-key': sessionToken.token})
      .type('application/json')
      .timeout(this._timeout));
    return body;
  }

  async nodes(sessionToken: SessionToken): Promise<Node[]> {
    const { body } = await this._makeRequest(() => request
      .get(`${this._endpoint}/v1/nodes`)
      .set({'x-api-key': sessionToken.token})
      .type('application/json')
      .timeout(this._timeout));
    return body;
  }

  async nodesCreate(sessionToken: SessionToken, address: string): Promise<Node[]> {
    const { body } = await this._makeRequest(() => request
      .post(`${this._endpoint}/v1/nodes`)
      .set({'x-api-key': sessionToken.token})
      .type('application/json')
      .timeout(this._timeout)
      .send({address}));
    return body;
  }

  async nodeDelete(sessionToken: SessionToken, address: string): Promise<Node[]> {
    const { body } = await this._makeRequest(() => request
      .post(`${this._endpoint}/v1/nodes/${address}/delete`)
      .set({'x-api-key': sessionToken.token})
      .type('application/json')
      .timeout(this._timeout));
    return body;
  }

  async docs(): Promise<DocsObj> {
    const { body } = await this._makeRequest(() => request
      .get(`${this._endpoint}/v1/docs`)
      .type('application/json')
      .timeout(this._timeout));
    return body;
  }

}
