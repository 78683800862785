import React from 'react';
import { PoktAccountCard } from './pokt-account-card';
import { FlexColumn } from './flex';
import { ChainsCard } from './chains-card';

export const Sidebar = () => {

  const styles = {
    container: {
      width: 480,
      position: 'relative',
    },
    innerContainer: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      overflow: 'hidden',
    },
    chainCardContainer: {
      position: 'relative',
      minHeight: 0,
    },
  };

  return (
    <div style={styles.container as React.CSSProperties}>
      <FlexColumn style={styles.innerContainer as React.CSSProperties} justifyContent={'flex-start'}>
        <div className={'ml-2 mr-2 mt-2'}>
          <PoktAccountCard />
        </div>
        <div style={styles.chainCardContainer as React.CSSProperties} className={'ml-2 mr-2 mt-2 mb-2 flex-grow-1'}>
          <ChainsCard />
        </div>

      </FlexColumn>
    </div>
  );
};
