import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { Navbar } from './navbar';

interface BodyContainerProps {
  children: any
}

export const BodyContainer = ({ children }: BodyContainerProps) => {

  const {
    account,
  } = useSelector(({ appState }: RootState) => appState);

  const styles = {
    container: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      overflowX: 'hidden',
      overflowY: 'hidden',
    },
    childrenContainer: {
      overflowX: 'hidden',
      overflowY: 'hidden',
      minHeight: 0,
      position: 'relative',
      flexGrow: 1,
    },
  };

  return (
    <div style={styles.container as React.CSSProperties} className={'d-flex flex-column justify-content-start'}>
      {account ? <Navbar /> : null}
      <div style={styles.childrenContainer as React.CSSProperties}>
        {children}
      </div>
    </div>
  )
};
