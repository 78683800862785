import React, { useRef, useState } from 'react';
import { FlexColumn, FlexRow } from './ui/flex';
import { Link } from 'react-router-dom';
import { routes, RECAPTCHA_SITEKEY, apiPath } from '../constants';
import daLogo from '../images/da_logo_white-400.png';
import $ from 'jquery';
import ReCaptcha from 'react-google-recaptcha';
import request from 'superagent';
import isString from 'lodash/isString';
import { emailPatt, handleError } from '../util';
import ccLogoText from '../images/cc_logo_text.svg';

export const Invite = () => {

  const [ email, setEmail ] = useState('');
  const [ successMessage, setSuccessMessage ] = useState('');
  const [ warningMessage, setWarningMessage ] = useState('');
  const [ errorMessage, setErrorMessage ] = useState('');
  const [ disabled, setDisabled ] = useState(false);

  const styles = {
    container: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
    formContainer: {
      marginTop: -100,
    },
    warningMessage: {
      marginBottom: 16,
    },
    logo: {
      width: 200,
    },
    submitButton: {
      marginTop: 16,
    },
  };

  const captchaRef = useRef(null);

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value.trim().toLowerCase());
  };
  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setErrorMessage('');
    setWarningMessage('');
    const preppedEmail = email.trim().toLowerCase();
    if(!emailPatt.test(preppedEmail)) {
      setWarningMessage('Please enter a valid email address.');
      $('#js-emailInput').trigger('focus');
      return;
    }
    // @ts-ignore
    const token = captchaRef ? captchaRef.current.getValue() : '';
    if(!token) {
      setErrorMessage('reCAPTCHA not found. Please reload page.');
      return;
    }
    setDisabled(true);

    try {
      const { body: expiration } = await request
        .post(`${apiPath}/v1/invite`)
        .type('application/json')
        .send({
          email,
          recaptchaToken: token,
        });
      const date = new Date(expiration);
      setSuccessMessage(`Invitation has been sent to ${email} and is valid until ${date.toLocaleTimeString()} on ${date.toLocaleDateString()}.`);
    } catch(err: any) {
      handleError(err);
      const body = err?.response?.body;
      const message =  body && isString(body) ? body : err.message;
      setErrorMessage(message);
      setDisabled(false);
      // @ts-ignore
      captchaRef.current.reset();
    }
  };

  return (
    <FlexColumn style={styles.container as React.CSSProperties} justifyContent={'center'}>

      <div className={'flex-grow-1'} />

      <div className={'row'}>
        <div className={'col'} />
        <div className={'col-sm-8 col-md-6 col-lg-4 col-xl-3'} style={styles.formContainer}>
          <div className={'pl-1 pr-1'}>

            <div className={'mb-2'}>
              <img className={'w-100'} alt={'Community Chains'} src={ccLogoText} />
            </div>
            <h2 className={'text-center'}>Request Invitation</h2>

            <p className={'mt-2'}>Welcome to Community Chains! To request an invite, please enter your email address and click Submit. You will receive an email with a link to where you can register for an account. Your invitation will be good for twenty-four hours.</p>

            <form onSubmit={onSubmit}>

              <div className={'form-group'}>
                <label>Email:</label>
                <input type={'email'} className={'form-control'}
                       onChange={onEmailChange} value={email} placeholder={'Enter email address'}
                       id={'js-emailInput'} readOnly={disabled || !!successMessage} required autoFocus />
              </div>

              {successMessage ?
                <div className={'text-success'} style={styles.warningMessage}>{successMessage}</div>
                :
                null
              }

              {warningMessage ?
                <div className={'text-warning'} style={styles.warningMessage}>{warningMessage}</div>
                :
                null
              }

              {errorMessage ?
                <div className={'text-danger'} style={styles.warningMessage}>{errorMessage}</div>
                :
                null
              }

              {successMessage ?
                null
                :
                <ReCaptcha sitekey={RECAPTCHA_SITEKEY}
                           theme={'dark'} ref={captchaRef}/>
              }

              {successMessage ?
                null
                :
                <div className={'form-group'}>
                  <button style={styles.submitButton} type={'submit'} className={'btn btn-primary btn-lg d-block w-100'} disabled={disabled}>Submit</button>
                </div>
              }

            </form>

            {successMessage ?
              null
              :
              <div className={'text-center'}>
                Already registered? <Link to={routes.HOME}>Login.</Link>
              </div>
            }

          </div>
        </div>
        <div className={'col'} />
      </div>

      <div className={'flex-grow-1'} />

      <FlexRow justifyContent={'center'}>
        <img style={styles.logo} className={'mb-1'} src={daLogo} alt={'Decentralized Authority'} />
      </FlexRow>

    </FlexColumn>
  );
};
