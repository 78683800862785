import React, { useState } from 'react';
import { FlexColumn, FlexRow } from './ui/flex';
import { Link } from 'react-router-dom';
import { apiPath, routes, staticLinks } from '../constants';
import daLogo from '../images/da_logo_white-400.png';
import $ from 'jquery';
import request from 'superagent';
import isString from 'lodash/isString';
import swal from 'sweetalert';
import { handleError } from '../util';
import { RegisterHandlerPostBody } from '../interfaces';

export const Register = () => {

  const queryParams =  new URLSearchParams(window.location.search);
  let email = queryParams.get('email') || '';
  email = decodeURIComponent(email);
  const invitation = queryParams.get('invitation') || '';

  const [ password, setPassword ] = useState('');
  const [ passwordRepeat, setPasswordRepeat ] = useState('');
  const [ warningMessage, setWarningMessage ] = useState('');
  const [ errorMessage, setErrorMessage ] = useState('');
  const [ disabled, setDisabled ] = useState(false);
  const [ agreePrivacyPolicy, setAgreePrivacyPolicy ] = useState(false);
  const [ agreeTos, setAgreeTos ] = useState(false);
  const [ agreeCookies, setAgreeCookies ] = useState(false);
  const [ agreeSharedWallet, setAgreeSharedWallet ] = useState(false);
  const [ domain, setDomain ] = useState<string>('');

  const styles = {
    container: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
    formContainer: {
      marginTop: -100,
    },
    warningMessage: {
      marginBottom: -4,
    },
    logo: {
      width: 200,
    },
  };

  const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };
  const onPasswordRepeatChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordRepeat(e.target.value);
  };
  const onDomainChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDomain(e.target.value.trim().toLowerCase());
  };
  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setErrorMessage('');
    setWarningMessage('');
    const preppedEmail = email.trim().toLowerCase();
    const preppedInvitation = invitation.trim();
    if(!preppedInvitation) {
      setWarningMessage('Invalid invitation.');
      return;
    } else if(!preppedEmail) {
      setWarningMessage('Invalid email address.');
      return;
    } if(password.length < 12) {
      setWarningMessage('Password must be at least 12 characters long.');
      $('#js-passwordInput').trigger('focus');
      return;
    } else if(password !== passwordRepeat) {
      setWarningMessage('Password and repeated password must match.');
      return;
    } else if(!agreePrivacyPolicy) {
      setWarningMessage('You must agree to the Privacy Policy.');
      return;
    } else if(!agreeTos) {
      setWarningMessage('You must agree to the Terms of Service.');
      return;
    } else if(!agreeCookies) {
      setWarningMessage('You must agree to allow cookies.');
      return;
    } else if(!agreeSharedWallet) {
      setWarningMessage('You must agree to the Shared Wallet Terms.');
      return;
    }
    setDisabled(true);
    const postBody: RegisterHandlerPostBody = {
      email: preppedEmail,
      password,
      invitation: preppedInvitation,
      domain,
      agreeTos,
      agreePrivacyPolicy,
      agreeCookies,
      agreeSharedWallet,
    };
    try {
      const { body } = await request
        .post(`${apiPath}/v1/register`)
        .type('application/json')
        .send(postBody);
      await swal({
        icon: 'success',
        title: 'Success!',
        text: `Account successfully created for ${body.email}. You may now login.`,
      });
      // @ts-ignore
      window.location = '/';
    } catch(err: any) {
      handleError(err);
      const body = err?.response?.body;
      const message =  body && isString(body) ? body : err.message;
      setErrorMessage(message);
      setDisabled(false);
    }
  };

  const disableSubmit = !agreeTos || !agreePrivacyPolicy || !agreeCookies || !agreeSharedWallet || !password || !passwordRepeat || !email || !invitation;

  return (
    <FlexColumn style={styles.container as React.CSSProperties} justifyContent={'center'}>

      <div className={'flex-grow-1'} />

      <div className={'row'}>
        <div className={'col'} />
        <div className={'col-sm-8 col-md-6 col-lg-4 col-xl-3'} style={styles.formContainer}>
          <div className={'pl-1 pr-1'}>

            <h1 className={'text-center'}>Community Chains</h1>
            <h1 className={'text-center'}>Registration</h1>
            <form onSubmit={onSubmit}>

              <div className={'form-group'}>
                <label>Email:</label>
                <input type={'email'} className={'form-control'} value={email}
                       spellCheck={false} id={'js-emailInput'} readOnly required />
              </div>

              <div className={'form-group'}>
                <label>Password: <em className={'text-muted'}>(minimum 12 characters)</em></label>
                <input type={'password'} className={'form-control'}
                       onChange={onPasswordChange} value={password} spellCheck={false}
                       id={'js-passwordInput'} readOnly={disabled} required autoFocus />
              </div>

              <div className={'form-group'}>
                <label>Repeat Password:</label>
                <input type={'password'} className={'form-control'} spellCheck={false}
                       onChange={onPasswordRepeatChange} value={passwordRepeat}
                       id={'js-passwordRepeatInput'} readOnly={disabled} required />
              </div>

              <div className={'form-group'}>
                <label>Top Level Domain: <em className={'text-muted'}>(e.g. mystakednodes.com)</em></label>
                <input type={'text'} className={'form-control'} spellCheck={false}
                       onChange={onDomainChange} value={domain}
                       id={'js-domainInput'} readOnly={disabled} required />
              </div>

              <div className={'form-group'}>

                <div className="form-check">
                  <input className="form-check-input" type="checkbox" checked={agreePrivacyPolicy} onChange={() => setAgreePrivacyPolicy(!agreePrivacyPolicy)} id="privacyPolicyCheckbox" />
                  <label className="form-check-label" htmlFor="privacyPolicyCheckbox">
                    I agree to the <a href={staticLinks.PRIVACY_POLICY} target={'_blank'}>Privacy Policy</a>.
                  </label>
                </div>

                <div className="form-check">
                  <input className="form-check-input" type="checkbox" checked={agreeTos} onChange={() => setAgreeTos(!agreeTos)} id="tosCheckbox" />
                  <label className="form-check-label" htmlFor="tosCheckbox">
                    I agree to the <a href={staticLinks.TERMS_OF_SERVICE} target={'_blank'}>Terms of Service</a>.
                  </label>
                </div>

                <div className="form-check">
                  <input className="form-check-input" type="checkbox" checked={agreeCookies} onChange={() => setAgreeCookies(!agreeCookies)} id="cookiesCheckbox" />
                  <label className="form-check-label" htmlFor="cookiesCheckbox">
                    I agree to allow cookies. See the <a href={staticLinks.COOKIE_POLICY} target={'_blank'}>cookie policy</a>.
                  </label>
                </div>

                <div className="form-check">
                  <input className="form-check-input" type="checkbox" checked={agreeSharedWallet} onChange={() => setAgreeSharedWallet(!agreeSharedWallet)} id="sharedWalletCheckbox" />
                  <label className="form-check-label" htmlFor="sharedWalletCheckbox">
                    I agree to the creation of a Shared Wallet. See the <a href={staticLinks.TERMS_OF_SERVICE} target={'_blank'}>ToS</a>.
                  </label>
                </div>

              </div>

              {warningMessage ?
                <div className={'text-warning'} style={styles.warningMessage}>{warningMessage}</div>
                :
                null
              }

              {errorMessage ?
                <div className={'text-danger'} style={styles.warningMessage}>{errorMessage}</div>
                :
                null
              }

              <div className={'form-group'}>
                <button type={'submit'} className={'btn btn-primary btn-lg d-block w-100 mt-3'} disabled={disabled || disableSubmit}>Register</button>
              </div>

            </form>

            <div className={'text-center'}>
              Already registered? <Link to={routes.HOME}>Login.</Link>
            </div>

          </div>
        </div>
        <div className={'col'} />
      </div>

      <div className={'flex-grow-1'} />

      <FlexRow justifyContent={'center'}>
        <img style={styles.logo} className={'mb-1'} src={daLogo} alt={'Decentralized Authority'} />
      </FlexRow>

    </FlexColumn>
  );
};
